import React from 'react';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import AuthProvider from './src/context/auth';

export const onRouteUpdate = ({ location, prevLocation }) => {
  console.log('new pathname', location.pathname);
  console.log('old pathname', prevLocation ? prevLocation.pathname : null);
};

export function wrapPageElement({ element, props }) {
  const Layout = element.type.Layout ?? React.Fragment;
  console.log('Wrap: ', element.type.isNavbarBackgroundEmpty, props);
  const isNavbarBackgroundEmpty = element.type.isNavbarBackgroundEmpty ?? false;
  return (
    <AuthProvider>
      <Layout {...props} isNavbarBackgroundEmpty={isNavbarBackgroundEmpty}>
        {element}
      </Layout>
    </AuthProvider>
  );
}
