import React, { createContext, useState, useEffect } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { BuyerInternal } from '../interfaces/buyer-internal';

export const AuthContext = createContext<{
  authUser: firebase.User | null;
  user: BuyerInternal | null;
  setAuthUser?: any;
  setUser?: any;
}>({
  authUser: null,
  user: null
});

const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState<firebase.User | null>();
  const [user, setUser] = useState<BuyerInternal | null>();

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => setAuthUser(user));
  }, []);

  useEffect(() => {
    if (authUser) {
      (async () => {
        const snapshot = await firebase.firestore().collection('buyers').doc(authUser.uid).get();
        setUser(snapshot.data() as BuyerInternal);
      })();
    } else setUser(null);
  }, [authUser]);

  return (
    <AuthContext.Provider
      value={{
        authUser,
        setAuthUser,
        user,
        setUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
